exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-contentful-blog-post-slug-tsx": () => import("./../../../src/pages/blog/{ContentfulBlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contentful-global-page-slug-ts": () => import("./../../../src/pages/{ContentfulGlobalPage.slug}.ts" /* webpackChunkName: "component---src-pages-contentful-global-page-slug-ts" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-seasons-contentful-season-page-year-members-tsx": () => import("./../../../src/pages/seasons/{ContentfulSeasonPage.year}/members.tsx" /* webpackChunkName: "component---src-pages-seasons-contentful-season-page-year-members-tsx" */),
  "component---src-pages-seasons-contentful-season-page-year-tsx": () => import("./../../../src/pages/seasons/{ContentfulSeasonPage.year}.tsx" /* webpackChunkName: "component---src-pages-seasons-contentful-season-page-year-tsx" */),
  "component---src-pages-seasons-tsx": () => import("./../../../src/pages/seasons.tsx" /* webpackChunkName: "component---src-pages-seasons-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */)
}

